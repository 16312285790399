import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VForm } from 'vuetify/lib/components/VForm';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{staticStyle:{"padding":"15px","border-radius":"5px"}},[_c(VCardTitle,{staticClass:"d-flex justify-center pb-8"},[_vm._v(" Cadastrar "+_vm._s(_vm.textType)+" ")]),_c(VCardText,{staticStyle:{"display":"flex","flex-direction":"column"}},[_c('div',{staticClass:"separator-title"},[_c('p',{staticClass:"px-2"},[_vm._v(" "+_vm._s(_vm.separatorText)+" ")])]),_c(VForm,{ref:"form",on:{"submit":function($event){$event.preventDefault();return _vm.handleRequest()}}},[_c(VTextField,{staticClass:"mt-6 mb-0",attrs:{"label":_vm.inputLabel,"rules":[_vm.rules.required],"outlined":"","dense":""},on:{"keydown:key":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.handleRequest()}},model:{value:(_vm.nameField),callback:function ($$v) {_vm.nameField=$$v},expression:"nameField"}})],1),(_vm.typeOfTab === 'process')?_c(VSwitch,{staticClass:"ml-2",attrs:{"label":"Ativo","color":"success","inset":""},model:{value:(_vm.activeProcess),callback:function ($$v) {_vm.activeProcess=$$v},expression:"activeProcess"}}):_vm._e(),_c('div',{staticClass:"d-flex justify-end",staticStyle:{"gap":"25px"}},[_c(VBtn,{attrs:{"color":"error"},on:{"click":function($event){_vm.closeModal(), _vm.resetForm()}}},[_vm._v(" Cancelar ")]),_c(VBtn,{attrs:{"color":"info","loading":_vm.isLoadingRequest},on:{"click":function($event){return _vm.handleRequest()}}},[_vm._v(" Salvar ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }